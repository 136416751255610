import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft, QrCodeScan, Speedometer2 } from 'react-bootstrap-icons';

const Header = ({ 
  leftIcon = 'arrow', // 'arrow' or 'qr'
  showSpeedometer = true,
  onLeftIconClick
}) => {
  const renderLeftIcon = () => {
    if (leftIcon === 'qr') {
      return <QrCodeScan color="#e51743" size={25} />;
    }
    return <ArrowLeft color="#e51743" size={25} />;
  };

  return (
    <div className="App-header">
      <div className="d-flex justify-content-between align-items-center w-100 px-3 position-relative">
        <div className="back-btn">
          <Link to={"/"} onClick={onLeftIconClick}>
            {renderLeftIcon()}
          </Link>
        </div>
        <div className="logo-container ">
          <img className="App-logo" alt="bobmc" src="Logo.png" />
        </div>
        {showSpeedometer && (
          <div className="control-room-btn">
            <Link to={"/livetracking"}>
              <Speedometer2 size={25} color="#e51743" />
            </Link>
          </div>
        )}
        {!showSpeedometer && (
          <div style={{ width: '25px' }}></div> // Spacer for alignment
        )}
      </div>
    </div>
  );
};

export default Header; 