import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container text-center">
        <p className="mb-0">
          Powered by{' '}
          <a 
            href="https://webtrip.in" 
            target="_blank" 
            rel="noopener noreferrer"
            className="footer-link"
          >
            Webtrip.in
          </a>
        </p>
      </div>
    </footer>
  );
};

export default Footer; 