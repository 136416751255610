// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import {
  
  getAuth, 
  signInWithEmailAndPassword, 
  signOut,
} from "firebase/auth";
import { getFirestore } from 'firebase/firestore';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDTsc1S1Nlpgq5aNt0LNHN85lAW3UMvLwU",
  authDomain: "bobmc-registration.firebaseapp.com",
  projectId: "bobmc-registration",
  storageBucket: "bobmc-registration.appspot.com",
  messagingSenderId: "163120878120",
  appId: "1:163120878120:web:fc42172c9ba7d998ee5b1d",
 
};


const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

const logInWithEmailAndPassword = async (email, password) => {
  try {
    await signInWithEmailAndPassword(auth, email, password);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};

const signOut1 = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
export  { auth, db, logInWithEmailAndPassword, signOut1 };



