// Filename - main.js

import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";
import { useAuthState } from "react-firebase-hooks/auth";
import {  addDoc,  collection,  getDocs,  query,  where, Timestamp, doc, updateDoc} from "firebase/firestore";
import { PersonCheck, PersonCheckFill} from "react-bootstrap-icons";
import Header from './components/Header';
import Footer from './components/Footer';
const ClubReader = () => {
  const [user, loading, error] = useAuthState(auth);
  const [clubMembers, setClubMembers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();

  const [pnrCounts, setPnrCounts] = useState({
    CC: 0,
    DS: 0,
    TS: 0,
    CO: 0,
    SL: 0
  });

const [activeFilter, setActiveFilter] = useState(null);
const [filteredMembers, setFilteredMembers] = useState([]);

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
    
    if (location.state?.club) {
      fetchClubMembers(location.state.club);
    } else {
      navigate("/");
    }
  }, [user, loading, location.state]);

  const fetchClubMembers = async (clubName) => {
    try {
      const q = query(collection(db, "users"), where("club", "==", clubName));
      const querySnapshot = await getDocs(q);
      const members = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }))
      .filter(member => member.PNR != null); // Filter out members without PNR
      // Sort the users list 
      members.sort((a, b) => { 
      if (a.name < b.name) 
        return -1; 
      if (a.name > b.name) 
        return 1; 
      return 0; 
    });
      
      setClubMembers(members);
      setFilteredMembers(members); // Initialize filtered members
      calculatePnrCounts(members);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching club members:", error);
      setIsLoading(false);
    }
  };

   // Add this after fetching club members in fetchClubMembers function
   const calculatePnrCounts = (members) => {
    const counts = {
      CC: 0,
      DS: 0,
      TS: 0,
      CO: 0,
      SL: 0
    };

    members.forEach(member => {
      if (member.PNR?.includes('CC')) counts.CC++;
      if (member.PNR?.includes('DS')) counts.DS++;
      if (member.PNR?.includes('TS')) counts.TS++;
      if (member.PNR?.includes('CO')) counts.CO++;
      if (member.PNR?.includes('SL')) counts.SL++;
    });

    setPnrCounts(counts);
  };

  const handleFilterClick = (filterType) => {
    if (activeFilter === filterType) {
      // If clicking the same filter, remove it
      setActiveFilter(null);
      setFilteredMembers(clubMembers);
    } else {
      // Apply new filter
      setActiveFilter(filterType);
      setFilteredMembers(clubMembers.filter(member => 
        member.PNR?.includes(filterType)
      ));
    }
  };

  const updateStatusChecked = async (id) => {
    // remote Db update  
    const checkinData = {
      checkIntime: Timestamp.fromDate(new Date()), 
      checkedInBY: user.email,
    } 
    try {
      const docRef = doc(db, "users", id); 
    await  updateDoc(docRef, checkinData);

     // Update both clubMembers and filteredMembers states
     const updateMembersList = (members) =>
      members.map(member => 
        member.id === id 
          ? { 
              ...member, 
              checkIntime: Timestamp.fromDate(new Date()),
              checkedInBY: user.email
            }
          : member
      );
       // Update the local state to reflect the change
       setClubMembers( prevMembers => updateMembersList(prevMembers) );      
       setFilteredMembers(prevMembers => updateMembersList(prevMembers));
    } catch (e) {
      console.error("Error updating check-in status:", error);
    }    
  };
 
  const removeStatusChecked = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to remove the check-in status?");    
    if (!isConfirmed) {
      return; // Exit if user cancels
    }  
    // Remove check-in data
    const checkinData = {
      checkIntime: null,            
      checkedInBY: null,
    }   
    try {
      const docRef = doc(db, "users", id); 
      await updateDoc(docRef, checkinData);      
      // Update the local state to reflect the change
      const updateMembersList = (members) =>
        members.map(member => 
          member.id === id 
            ? { 
                ...member, 
                checkIntime: null,
                checkedInBY: null
              }
            : member
        );
        setClubMembers( prevMembers => updateMembersList(prevMembers) );      
        setFilteredMembers(prevMembers => updateMembersList(prevMembers));       
    } catch (error) {
      console.error("Error removing check-in status:", error);
      alert("Error removing check-in status");
    } 
  }; 

  const checkInAllMembers = async () => {
    // Determine which members to check in based on current filter
    const membersToCheckIn = activeFilter ? filteredMembers : clubMembers;
    const memberCount = membersToCheckIn.length;
    
    const isConfirmed = window.confirm(
      `Are you sure you want to check in ${memberCount} ${activeFilter || 'all'} members?`
    );
    
    if (!isConfirmed) return;

    setIsLoading(true);
    const checkinData = {
      checkIntime: Timestamp.fromDate(new Date()),
      checkedInBY: user.email,
    };

    try {
      // Create an array of promises for all updates
      const updatePromises = membersToCheckIn
        .filter(member => !member.checkIntime) // Only update members who aren't checked in
        .map(member => {
          const docRef = doc(db, "users", member.id);
          return updateDoc(docRef, checkinData);
        });

      // Execute all updates
      await Promise.all(updatePromises);

      // Update local state for both clubMembers and filteredMembers
      const updateMembersList = (members) =>
        members.map(member => 
          membersToCheckIn.some(m => m.id === member.id) && !member.checkIntime
            ? { 
                ...member, 
                checkIntime: Timestamp.fromDate(new Date()),
                checkedInBY: user.email
              }
            : member
        );

      setClubMembers(prevMembers => updateMembersList(prevMembers));
      setFilteredMembers(prevMembers => updateMembersList(prevMembers));

      const checkedInCount = updatePromises.length;
      alert(`Successfully checked in ${checkedInCount} members!`);
    } catch (error) {
      console.error("Error checking in members:", error);
      alert("Error checking in members");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
    <div class="container main-content">
     <Header leftIcon="qr" />
      <div className="container mt-4 text-center">
        <div >
          <div className="">
            <h3>{location.state?.club}</h3>
            <div className="pnr-counts small text-muted d-flex justify-content-center">
              <span 
                className={`me-3 pnr-count ${activeFilter === 'CC' ? 'active' : ''}`}
                onClick={() => handleFilterClick('CC')}
                style={{ cursor: 'pointer' }}
              >
                CC: {pnrCounts.CC}
              </span>
              <span 
                className={`me-3 pnr-count ${activeFilter === 'DS' ? 'active' : ''}`}
                onClick={() => handleFilterClick('DS')}
                style={{ cursor: 'pointer' }}
              >
                DS: {pnrCounts.DS}
              </span>
              <span 
                className={`me-3 pnr-count ${activeFilter === 'TS' ? 'active' : ''}`}
                onClick={() => handleFilterClick('TS')}
                style={{ cursor: 'pointer' }}
              >
                TS: {pnrCounts.TS}
              </span>
              <span 
                className={`me-3 pnr-count ${activeFilter === 'CO' ? 'active' : ''}`}
                onClick={() => handleFilterClick('CO')}
                style={{ cursor: 'pointer' }}
              >
                CO: {pnrCounts.CO}
              </span>
              <span 
                className={`me-3 pnr-count ${activeFilter === 'SL' ? 'active' : ''}`}
                onClick={() => handleFilterClick('SL')}
                style={{ cursor: 'pointer' }}
              >
                SL: {pnrCounts.SL}
              </span>
              <span 
                className="pnr-count"
                onClick={() => setActiveFilter(null)}
                style={{ cursor: 'pointer' }}
              >
                Total: {clubMembers.length}
              </span>
              
            </div>
          </div>
         
          
        </div>
        <button 
            className="btn btn-primary mt-3 mb-3"
            onClick={checkInAllMembers}
            disabled={isLoading}
          >
              {isLoading ? "Processing..." : `Check-in ${activeFilter || 'All'} Members`}

          </button>
        {isLoading ? (
          <div>Loading...</div>
        ) : (
          <div className="table-responsive">
            <table className="table">
              <thead>
                <tr>
                  <th>Name <br></br>PNR</th>
                  <th>Check-in Status</th>
                </tr>
              </thead>
              <tbody>
              {(activeFilter ? filteredMembers : clubMembers).map((member) => (
                  <tr key={member.id}>
                    <td>{member.name}<br></br>{member.PNR}</td>
                      <td style={{ cursor: 'pointer' }}>
                      {member.checkIntime ? (
                        <PersonCheckFill 
                          className="text-success" 
                          size={20} 
                          onClick={() => removeStatusChecked(member.id)}
                        />
                      ) : (
                        <PersonCheck 
                          className="text-warning" 
                          size={20} 
                          onClick={() => updateStatusChecked(member.id)}
                        />
                      )}
                    </td>                   
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default ClubReader;
