import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { auth, logInWithEmailAndPassword, db } from "./firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection,  getDocs,  query,  where, } from "firebase/firestore";
import { signOut } from "firebase/auth";
import Footer from './components/Footer';
function Login() {
  
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const navigate = useNavigate();

  // Add role check function
  const checkUserRole = async (userEmail) => {
    try {
      const q = query(collection(db, "roles"), where("email", "==", userEmail));
      const querySnapshot = await getDocs(q);
      if(querySnapshot.empty){
        localStorage.setItem('userRole', 'user');  
      }
      else{
      // Store role in localStorage for easy access
      localStorage.setItem('userRole', 'admin');
      }
    } catch (error) {
      console.error("Error checking role:", error);
    
    }
  };

  const loginEmailAndPassword = async (email, password) => {
    try {
      const userCredential = await logInWithEmailAndPassword(email, password);
      await checkUserRole(email);
      
    
      
      navigate("/dashboard");
    } catch (err) {
      console.error(err);
      alert(err.message);
    }
  };

  useEffect(() => {
    if (loading) return;
    if (user) {
     
          navigate("/dashboard");
        
    }
  }, [user, loading]);

  return (
    <>
    <div className="login container main-content">
      <div className="App-header">
          <img width={100} alt="bobmc" src="Logo.png" />
      </div>
      <div className="login__container">
        <input
          type="text"
          className="login__textBox"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="E-mail Address"
        />
        <input
          type="password"
          className="login__textBox"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button
          className="login__btn"
          onClick={() => loginEmailAndPassword(email, password)}
        >
          Login
        </button>
      </div>
    </div>
    <Footer />
    </>
  );
}

export default Login;
