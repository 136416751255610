// Filename - main.js

import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import QrReader from "react-qr-reader";
import { useAuthState } from "react-firebase-hooks/auth";
import {  addDoc,  collection,  getDocs,  query,  where, Timestamp, doc, updateDoc} from "firebase/firestore";
import { PersonCheck, PersonCheckFill} from "react-bootstrap-icons";
import Header from './components/Header';
import Footer from './components/Footer';

const Mainpage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);

  // Add this array of admin emails
  // const ADMIN_EMAILS = [
  //   "dkalappa@gmail.com",
  //   "preet2u@gmail.com",
  //   // Add all admin email addresses here
  // ];

 

  const location = useLocation();
  let tmpLocation;
  if (location.state) 
    tmpLocation = location.state.hotel;
  else {
   
  }
 
  const [message, setMessage] = useState([]);
  const [ticketDetails, setTicketDetails] = useState([]);
  const [clubMembers, setClubMembers] = useState([]);
  const [showClubMembers, setShowClubMembers] = useState(false);

 

  const fetchLocalUserDetails = async (pnr, club) => {
 //   const users = JSON.parse(localStorage.getItem("users"));
    const query1 = query(collection(db, "users"), where("PNR", "==", pnr) );    
    // Get the documents for each query
    const querySnapshot = await getDocs(query1)
    if(querySnapshot.empty)
    {      
        console.log("No user found with this PNR.");
        setMessage(
          <>
            Please check the pnr            
          </>
        );
        alert("Please check the pnr ");    
        setTicketDetails("")
      
    }
    const users = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      data: doc.data(),
    }));
    
    users.forEach(async(tuser) => {     
        // update checkedin time
        await updateStatusChecked(tuser.id)         
          const dTicket = displayResults(tuser.data, pnr);
          if(tuser.data.checkIntime != null){
            setMessage("Rider already checked in by "+tuser.data.checkedInBY);
            alert("Rider already checked in by "+tuser.data.checkedInBY);           
            setTicketDetails(dTicket)
          }
           else { 
             // Display the user details            
             navigate("/rmqrdetails", { state: { dTicket } });
          }
         
    });
   
  };

   const updateStatusChecked = async (id) => {
    // remote Db update
  
    const checkinData = {
      checkIntime: Timestamp.fromDate(new Date()), 
      checkedInBY: name,
    } 
    try {
      const docRef = doc(db, "users", id); 
    await  updateDoc(docRef, checkinData);
       // Update the local state to reflect the change
       setClubMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === id 
            ? { ...member, checkIntime: Timestamp.fromDate(new Date()),  checkedInBY: name,}
            : member
        )
      );  
    
    
    } catch (e) {
      console.error("Error updating check-in status:", error);
    }
  
  
  };


  const displayResults = (data, pnr) => {
          
    const ticket = {};
   
    ticket["type"] = data.RM_2025;       
      ticket["pnr"] = data.PNR;
      ticket["name"] = data.name;
      ticket["email"] = data.email;
      ticket["club"] = data.club;
      ticket["bloodgroup"] = data.bloodGroup;
      ticket["food"] = data.food;
      ticket["tshirt"] = data.tshirt;
    
    //   setResult(ticket);
    return ticket
  };

  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");

    setName(user?.email);
    // Check if current user's email is in admin list
    const userRole = localStorage.getItem('userRole');
    if(userRole === 'admin')
      setIsAdmin(true);
  }, [user, loading]);

  const navigate = useNavigate();

  const previewStyle = {
    height: 240,
    width: 320,
  };
  const constraints = {
    facingMode: "environment",
  };
  const [result, setResult] = useState("No result");

  const handleScan = (data) => {
   
    if (data) {

      const pnrdata = data.match(/PNR: ([\w\s]+)/);
      const club = data.match(/Club: ([\w\s]+)/); 
      const mod = data.match(/passType: ([\w\s]+)/); 

      if (pnrdata && club && mod) {
        const pnr = pnrdata[1].trim();
        const clubName = club[1].trim();
        const passType = mod[1].trim();
 
      
          fetchLocalUserDetails(pnr, clubName);
        
     
      }else {
        console.log("Invalid QR code format");
        alert("Invalid QR code format");
      }
    }
  };

  const handleError = (error) => {
    console.error(error);
  };

  const fetchClubMembers = async (clubName) => {
    try {
      const q = query(collection(db, "users"), where("club", "==", clubName));
      const querySnapshot = await getDocs(q);
      const members = querySnapshot.docs.map(doc => ({

        id: doc.id,
        ...doc.data()
      }))
      .filter(member => member.PNR != null); // Filter out members without PNR
      // Sort the users list 
      members.sort((a, b) => { 
      if (a.name < b.name) 
        return -1; 
      if (a.name > b.name) 
        return 1; 
      return 0; 
    });
      
      setClubMembers(members);
      setShowClubMembers(true);
    } catch (error) {
      console.error("Error fetching club members:", error);
     
    }
  };

  const removeStatusChecked = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to remove the check-in status?");
    
    if (!isConfirmed) {
      return; // Exit if user cancels
    }
  
    // Remove check-in data
    const checkinData = {
      checkIntime: null,            
      checkedInBY: null,
    } 
  
    try {
      const docRef = doc(db, "users", id); 
      await updateDoc(docRef, checkinData);
      
      // Update the local state to reflect the change
      setClubMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === id 
            ? { ...member, checkIntime: null, checkedInBY: null }
            : member
        )
      );  
        
    } catch (error) {
      console.error("Error removing check-in status:", error);
      alert("Error removing check-in status");
    } 
  };

  return (
    <>
    <div class="container main-content">
      <Header leftIcon="arrow" />

      <center>
      {user ? (
        <div className="container">
          
            <div >
             
              <div className="text-danger">{message}</div>
              <br />
          
              <QrReader
                delay={500}
                onError={handleError}
                onScan={handleScan}
                style={previewStyle}
                facingMode={"environment"}
              />
            </div>
            
          <br clear="all" />
         
          <div style={{ marginTop: '5em' , marginBottom:'2em' }}>
          <div className="admin-controls mb-4">
          {/* Admin button */}
          {isAdmin && (
           ticketDetails && Object.keys(ticketDetails).length > 0 && (
            <div className="admin-controls mt-4">
              <button 
                className="btn btn-primary"
                onClick={() => navigate("/clubReader", { state: { club: ticketDetails.club } })}   

              >
                Club Check-ins
              </button>
            </div>
          )
        )
        }
              </div>
             
          {/* Ticket Details Table */}
          {!showClubMembers && Object.entries(ticketDetails).map(([key, value]) => (
            <div className="ticket-details-container">   
              <div className="trow" key={key}>
                <div className="cell uppercase">
                  <strong>{key}</strong>
                </div>
                <div className="cell text-break">
                  {key === "club" ? (
                    <div className="">
                      <span className="me-2">{value}</span>
                      <button 
                        className="btn btn-sm btn-primary"
                        onClick={() => fetchClubMembers(value)}
                      >
                        View
                      </button>
                    </div>
                  ) : (
                    value
                  )}
                </div>
              </div>
            </div>
          ))}
         
          {/* Club Members Table */}
          {showClubMembers && (
            <div>
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Club Members</h4>
                <button 
                  className="btn btn-secondary"
                  onClick={() => setShowClubMembers(false)}
                >
                  Back to Ticket
                </button>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                  <tr>
                      <th>Name<br></br>PNR</th>                      
                      <th>Check-in Status</th>
                    </tr>
                  </thead>
                  <tbody>
                  {clubMembers.map((member) => (
                      <tr key={member.id}>
                        <td>{member.name}<br></br>{member.PNR}</td>                                      
                        <td>
                          {member.checkIntime ? (
                            <span className="text-success">
                              <PersonCheckFill className="text-success" size={20} onClick={()=> removeStatusChecked(member.id) }/>
                            </span>
                          ) : (
                            <span style={{ cursor: 'pointer' }} className="text-warning"><PersonCheck className="text-warning" size={20} onClick={()=> updateStatusChecked(member.id) }/></span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
          </div>
        </div>

      ) : (
        
          <h3>404 Page Not Found </h3>
       
      )}
      </center>
    </div>
    <Footer />
    </>
  );
};

export default Mainpage;
