import React, { useEffect,  useState } from "react";
import { db, auth, signOut1 } from "./firebase";
import { useNavigate, Link } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { collection, getDocs, orderBy, query, where } from "firebase/firestore";

import { DatabaseDown, Eye, Power, QrCodeScan, Speedometer2 } from "react-bootstrap-icons";
import { Grid } from "@material-ui/core";
import DateDisplay from "./datedisplay";
import Moment from "react-moment";
import Footer from './components/Footer';
import { ArrowLeft, Gear } from "react-bootstrap-icons";
const Dashboard = () => {
  const [user, loading, error] = useAuthState(auth);
  const [hotellist, setHotellist] = useState([]);
  const [users, setUsers] = useState([]);
  const [nousers, setNoUsers] = useState(0);
  const [clubs, setClubs] = useState([]);
  
  let tmpCounter = "";
  if (localStorage.getItem("Counter")) {
    tmpCounter = localStorage.getItem("Counter");
  }
  const [Counter, setSelectedOption] = useState(tmpCounter);
  const [msg, setMessage] = useState("");

   
  const handleChange = (event) => {
    setSelectedOption(event.target.value);
       
  };
 
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
  
  }, [user, loading]);

  
  const navigate = useNavigate();

  return (
    <>
    <div className="container  main-content">
     <div className="App-header">
     <div className="d-flex justify-content-between align-items-center w-100 px-3">

          <div className="back-btn">
           
              <Power color="#e51743" size={25} onClick={() => signOut1().then(() => {
    console.log("User signed out");
    navigate("/"); // Redirect to login after sign out
  }).catch((error) => {
    console.error("Sign out error:", error);
  })}  />
          
          </div>
          <img className="App-logo" alt="bobmc" src="Logo.png" />
          <div className="control-room-btn">
          <Link to={"/livetracking"}>
              <Speedometer2 size={25} color="#e51743" />
          </Link>
        </div>
        </div>
      </div>

      <div className="qr-container">
        {user ? (
          <div className="qr-content">
         
              <div className="text-danger">{msg}</div>
              <div className="qr-icon">
                <QrCodeScan
                  color="#e51743"
                  size={150}
                  onClick={() => {
                   
                      navigate("/rmqrreader");
                   
                  }}                 
                  style={{ cursor: "pointer" }}
                />
                
              </div>           
            
         
          </div>
        ) : (
          <div></div>
        )}
      </div>
    </div>
    <Footer />
    </>
  );
};

export default Dashboard;
