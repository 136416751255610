// Filename - control.js

import React, { useEffect, useState } from "react";
import { db, auth } from "./firebase";

import { useAuthState } from "react-firebase-hooks/auth";
import {  collection,   query,  where, onSnapshot} from "firebase/firestore";
import { Search, X } from 'react-bootstrap-icons';
import Header from './components/Header';
import Footer from './components/Footer';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];
let totalcheckins =[]
const percentCheckin ={}
const ControlPage = () => {
  const [user, loading, error] = useAuthState(auth);
  const [name, setName] = useState("");
 
//  const [totalcheckins, setTotalCheckins] = useState([]);
  const [clubStats, setClubStats] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [riders, setRiders] = useState([]);
  const [pnrCounts, setPnrCounts] = useState({
    total: 0,
    checkedIn: 0,
    CC: { total: 0, checkedIn: 0 },
    DS: { total: 0, checkedIn: 0 },
    TS: { total: 0, checkedIn: 0 },
    CO: { total: 0, checkedIn: 0 },
    SL: { total: 0, checkedIn: 0 }
  });
  const [selectedClub, setSelectedClub] = useState(null);
  const [showModal, setShowModal] = useState(false);

  // Function to get club members
  const getClubMembers = (clubName) => {
    return riders.filter(rider => rider.club === clubName);
  };

   // Function to close modal
   const handleCloseModal = () => {
    setShowModal(false);
    setSelectedClub(null);
    // Remove modal classes from body
 
  
  };

  // Function to handle club click
  const handleClubClick = (club) => {
    setSelectedClub(club);
    setShowModal(true);
    // Add modal-open class to body
   
  };
  const PNR_NAMES = {
    'CC': 'Clan Cluster',
    'DS': 'Desert Squad',
    'CO': 'Cool Oasis',
    'SL': 'Starlight Camp',
    'TS': 'Twin Shack'
  };
  
  // Add this after fetching club members in fetchClubMembers function
  const calculatePnrCounts = (members) => {
    const counts = {
      CC: 0,
      DS: 0,
      TS: 0,
      CO: 0,
      SL: 0
    };
   
    members.forEach(member => {
      if (member.PNR?.includes('CC')) counts.CC++;
      if (member.PNR?.includes('DS')) counts.DS++;
      if (member.PNR?.includes('TS')) counts.TS++;
      if (member.PNR?.includes('CO')) counts.CO++;
      if (member.PNR?.includes('SL')) counts.SL++;
    });

    setPnrCounts(counts);
  };
    
  useEffect(() => {
    const unsubscribe = onSnapshot(
      query(collection(db, "users"),   where("transactionId", "!=", null ),        ),
      (snapshot) => {
        const ridersData = snapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setRiders(ridersData);

          // Calculate club-wise statistics
          const clubsMap = new Map();
        
          ridersData.forEach(rider => {
            if (rider.club) {
              if (!clubsMap.has(rider.club)) {
                clubsMap.set(rider.club, {
                  name: rider.club,
                  total: 0,
                  checkedIn: 0
                });
              }
              
              const clubStats = clubsMap.get(rider.club);
              clubStats.total++;
              if (rider.checkIntime) {
                clubStats.checkedIn++;
              }
            }
          });
          // Convert Map to array and sort by club name
          const clubStatsArray = Array.from(clubsMap.values())
          .sort((a, b) => a.name.localeCompare(b.name));
        
        setClubStats(clubStatsArray);
        // Calculate counts
        const counts = {
          total: ridersData.length,
          checkedIn: ridersData.filter(rider => rider.checkIntime).length,
          CC: { total: 0, checkedIn: 0 },
          DS: { total: 0, checkedIn: 0 },
          TS: { total: 0, checkedIn: 0 },
          CO: { total: 0, checkedIn: 0 },
          SL: { total: 0, checkedIn: 0 }
        };

        ridersData.forEach(rider => {
          const pnrType = getPNRType(rider.PNR);
          if (pnrType) {
            counts[pnrType].total++;
            if (rider.checkIntime) {
              counts[pnrType].checkedIn++;
            }
          }
        });

        setPnrCounts(counts);
      },
      (error) => {
        console.error("Error fetching riders:", error);
      }
    );

    return () => unsubscribe();
  }, []);

  // Helper function to determine PNR type
  const getPNRType = (pnr) => {
    if (!pnr) return null;
    if (pnr.includes('CC')) return 'CC';
    if (pnr.includes('DS')) return 'DS';
    if (pnr.includes('TS')) return 'TS';
    if (pnr.includes('CO')) return 'CO';
    if (pnr.includes('SL')) return 'SL';
    return null;
  };
  // Filter clubs based on search term
  const filteredClubs = clubStats.filter(club =>
    club.name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
     
    <div className="container mt-4 main-content
    ">
       <Header leftIcon="qr" />
      {/* PNR Counts Card */}
      <div className="card mt-4 mb-4">
        <div className="card-header">
          <h5 className="mb-0">Check-in Status</h5>
        </div>
        <div className="card-body">
          <div className="row g-3">
            <div className="col-md-4">
              <div className="border rounded p-3 text-center">
                <h6>Total Riders</h6>
                <h3>{pnrCounts.total}</h3>
                <small className="text-success">
                  {pnrCounts.checkedIn} Checked In
                </small>
              </div>
            </div>
            {Object.entries(pnrCounts)
              .filter(([key]) => ['CC', 'DS', 'TS', 'CO', 'SL'].includes(key))
              .map(([type, count]) => (
                <div key={type} className="col-md-4">
                  <div className="border rounded p-3 text-center">
                  <h6>{PNR_NAMES[type] || type}</h6>
                 
                    <h3>{count.total}</h3>
                    <small className="text-success">
                      {count.checkedIn} Checked In
                    </small>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>

      {/* Checked In Riders Table */}
    {/*   <div className="card">
        <div className="card-header">
          <h5 className="mb-0">Checked In Riders</h5>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>PNR</th>
                  <th>Check-in Time</th>
                  <th>Checked In By</th>
                </tr>
              </thead>
              <tbody>
                {riders
                  .filter(rider => rider.checkIntime)
                  .sort((a, b) => b.checkIntime - a.checkIntime)
                  .map(rider => (
                    <tr key={rider.id}>
                      <td>{rider.name}</td>
                      <td>{rider.PNR}</td>
                      <td>
                        {rider.checkIntime?.toDate().toLocaleString()}
                      </td>
                      <td>{rider.checkedInBY}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div> */}
      
      {/* Club Statistics Card */}
      <div className="card mb-4">
        <div className="card-header">
          <div className="d-flex justify-content-between align-items-center">
            <h5 className="mb-0">Club-wise Check-in Status</h5>
            <div className="search-box" style={{ width: '300px' }}>
              <div className="input-group">
                <span className="input-group-text">
                  <Search size={16} />
                </span>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search clubs..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                {searchTerm && (
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={() => setSearchTerm('')}
                  >
                    <X size={16} />
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Club Name</th>
                  <th>Total Members</th>
                  <th>Checked In</th>
                  <th>Progress</th>
                </tr>
              </thead>
              <tbody>
                {filteredClubs.map(club => (
                  <tr key={club.name}>
                   <td>
                      <span 
                        className="club-name-link"
                        onClick={() => handleClubClick(club)}
                        style={{ cursor: 'pointer', color: '#0d6efd' }}
                      >
                        {club.name}
                      </span>
                    </td>
                    <td>{club.total}</td>
                    <td>{club.checkedIn}</td>
                    <td>
                      <div className="progress" style={{ height: '20px' }}>
                        <div 
                          className="progress-bar bg-success" 
                          role="progressbar" 
                          style={{ 
                            width: `${(club.checkedIn / club.total) * 100}%` 
                          }}
                          aria-valuenow={club.checkedIn}
                          aria-valuemin="0"
                          aria-valuemax={club.total}
                        >
                          {Math.round((club.checkedIn / club.total) * 100)}%
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
                {filteredClubs.length === 0 && (
                  <tr>
                    <td colSpan="4" className="text-center">
                      No clubs found matching "{searchTerm}"
                    </td>
                  </tr>
                )}
              </tbody>
              <tfoot>
                <tr className="table-info">
                  <td><strong>Total</strong></td>
                  <td><strong>{filteredClubs.reduce((sum, club) => sum + club.total, 0)}</strong></td>
                  <td><strong>{filteredClubs.reduce((sum, club) => sum + club.checkedIn, 0)}</strong></td>
                  <td>
                    <div className="progress" style={{ height: '20px' }}>
                      <div 
                        className="progress-bar bg-info" 
                        role="progressbar" 
                        style={{ 
                          width: `${(filteredClubs.reduce((sum, club) => sum + club.checkedIn, 0) / 
                                   filteredClubs.reduce((sum, club) => sum + club.total, 0)) * 100}%` 
                        }}
                      >
                        {Math.round((filteredClubs.reduce((sum, club) => sum + club.checkedIn, 0) / 
                                   filteredClubs.reduce((sum, club) => sum + club.total, 0)) * 100)}%
                      </div>
                    </div>
                  </td>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>

   {/* Club Members Modal */}
   {showModal && selectedClub && (
      <>
        <div className="modal-backdrop show"></div>
        <div className="modal show d-block" tabIndex="-1">
          <div className="modal-dialog modal-lg modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">
                  {selectedClub.name} - Members List
                  <span className="ms-2 badge bg-primary">
                    {selectedClub.checkedIn}/{selectedClub.total} Checked In
                  </span>
                </h5>
                <button 
                  type="button" 
                  className="btn-close" 
                  onClick={handleCloseModal}
                ></button>
              </div>
              <div className="modal-body">
                <div className="table-responsive">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Name</th>
                        <th>PNR</th>
                        <th>Status</th>
                        <th>Check-in Time</th>
                        <th>Checked In By</th>
                      </tr>
                    </thead>
                    <tbody>
                      {getClubMembers(selectedClub.name)
                        .sort((a, b) => {
                          // Sort by check-in status first, then by name
                          if (a.checkIntime && !b.checkIntime) return -1;
                          if (!a.checkIntime && b.checkIntime) return 1;
                          return a.name.localeCompare(b.name);
                        })
                        .map(member => (
                          <tr key={member.id}>
                            <td>{member.name}</td>
                            <td>{member.PNR}</td>
                            <td>
                              {member.checkIntime ? (
                                <span className="badge bg-success">Checked In</span>
                              ) : (
                                <span className="badge bg-warning text-dark">Pending</span>
                              )}
                            </td>
                            <td>
                              {member.checkIntime 
                                ? member.checkIntime.toDate().toLocaleString()
                                : '-'}
                            </td>
                            <td>{member.checkedInBY || '-'}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="modal-footer">
                <button 
                  type="button" 
                  className="btn btn-secondary" 
                  onClick={handleCloseModal}
                >
                  Close
                </button>
              </div>
            </div>
          </div>
          
        </div>
        </>
      )}
    </div>
     <Footer />
     </>
  );
};

export default ControlPage;
