// Filename - main.js

import React, { useEffect, useState } from "react";
import { auth, db } from "./firebase";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { ArrowLeft, CheckCircleFill, PersonCheck, PersonCheckFill, Speedometer2, QrCodeScan } from "react-bootstrap-icons";
import { collection, doc, getDocs, query, updateDoc, where, Timestamp } from "firebase/firestore";
import Header from './components/Header';
import Footer from './components/Footer';

const Detailspage = () => {

  const [name, setName] = useState("");
  const [user, loading, error] = useAuthState(auth);
  const [clubMembers, setClubMembers] = useState([]);
  const [showClubMembers, setShowClubMembers] = useState(false);

  const [roomno, setRoomNo] = useState(""); // state for the input value
  const [message, setMessage] = useState(""); // state for the feedback message
 
 
  localStorage.setItem('prvPnr', '');
  useEffect(() => {
    if (loading) return;
    if (!user) return navigate("/");
      setName(user?.email);
  }, [user, loading]);
 
  const navigate = useNavigate();
  const [result, setResult] = useState("No result");

  const location = useLocation();
  const ticket = location.state.dTicket;

  const fetchClubMembers = async (clubName) => {
    try {
      const q = query(
        collection(db, "users"), 
        where("club", "==", clubName)
      );
      const querySnapshot = await getDocs(q);
      const members = querySnapshot.docs
        .map(doc => ({
          id: doc.id,
          ...doc.data()
        }))
        .filter(member => member.PNR != null); // Filter out members without PNR
       // Sort the users list 
       members.sort((a, b) => { 
        if (a.name < b.name) 
          return -1; 
        if (a.name > b.name) 
          return 1; 
        return 0; 
      });
        
      setClubMembers(members);
      setShowClubMembers(true);
    } catch (error) {
      console.error("Error fetching club members:", error);
    

      
    }
  };

  const removeStatusChecked = async (id) => {
    // Show confirmation dialog
    const isConfirmed = window.confirm("Are you sure you want to remove the check-in status?");
    
    if (!isConfirmed) {
      return; // Exit if user cancels
    }
  
    // Remove check-in data
    const checkinData = {
      checkIntime: null,            
      checkedInBY: null,
    } 
  
    try {
      const docRef = doc(db, "users", id); 
      await updateDoc(docRef, checkinData);
      
      // Update the local state to reflect the change
      setClubMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === id 
            ? { ...member, checkIntime: null, checkedInBY: null }
            : member
        )
      );  
        
    } catch (error) {
      console.error("Error removing check-in status:", error);
      alert("Error removing check-in status");
    } 
  };

  
  const updateStatusChecked = async (id) => {
    // remote Db update  
    const checkinData = {
      checkIntime: Timestamp.fromDate(new Date()),            
      checkedInBY: name,
    } 
    try {
      const docRef = doc(db, "users", id); 
    await  updateDoc(docRef, checkinData);
       // Update the local state to reflect the change
       setClubMembers(prevMembers => 
        prevMembers.map(member => 
          member.id === id 
            ? { ...member, checkIntime: Timestamp.fromDate(new Date()),  checkedInBY: name,}
            : member
        )
      );  
        
    } catch (e) {
      console.error("Error updating check-in status:", error);
    } 
  
  };

  return (
      <>
      <div className="container main-content">
      <Header leftIcon="qr" />
      {user ? (
        <center className="pt-5">
          <div className="col">
            <div className="pb-5"><CheckCircleFill className="text-success" size={70}/><h3 > Rider details</h3></div>
            <div className="trow" >
              
              
              
            </div>
           {/* Ticket Details Table */}
           {!showClubMembers && Object.entries(ticket).map(([key, value]) => (
           <div className="ticket-details-container">   
            <div className="trow" key={key}>
              <div className="cell uppercase">
                <strong>{key}</strong>
              </div>
              <div className="cell text-break">
                {key === "club" ? (
                   <div className="">
                   <span className="me-2">{value}</span>
                   <button 
                     className="btn btn-sm btn-primary"
                     onClick={() => fetchClubMembers(value)}
                   >
                      View </button>
                  </div>
                ) : (
                  value
                )}
              </div>
            </div>
            </div>
          ))}

          {/* Club Members Table */}
          {showClubMembers && (
            <div className="container">
              <div className="d-flex justify-content-between align-items-center mb-3">
                <h4>Club Members</h4>
                <button 
                  className="btn btn-secondary"
                  onClick={() => setShowClubMembers(false)}
                >
                  Back to Ticket
                </button>
              </div>
              <div className="table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Name<br></br>PNR</th>                      
                      <th>Check-in Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clubMembers.map((member) => (
                      <tr key={member.id}>
                        <td>{member.name}<br></br>{member.PNR}</td>                                      
                        <td>
                          {member.checkIntime ? (
                            <span className="text-success">
                             <PersonCheckFill className="text-success" size={20} onClick={()=> removeStatusChecked(member.id) }/>
                            </span>
                          ) : (
                            <span  style={{ cursor: 'pointer' }} className="text-warning"><PersonCheck className="text-warning" size={20} onClick={()=> updateStatusChecked(member.id) }/></span>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          )}
              
         
          </div>
        </center>
      ) : (
        <center>
          <h3>404 Page Not Found </h3>
        </center>
      )}
    </div>
    <Footer />
    </>
  );
};

export default Detailspage;
